<template>
  <div>
    <!-- div fijo -->
    <div class="fixHeightMine">
      <v-container grid-list-xs class="">
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field label="Correo electrónico" outlined dense hide-details>
            </v-text-field>
          </v-col>
          <v-col cols="12" md="8">
            <!-- vista desktop para el div -->
            <div class="d-none d-md-block">
              <div class="d-flex justify-start align-center">
                <v-btn color="primary" dense hide-details outlined>Enviar por email</v-btn>
                <p class="mb-0 px-4">o</p>
                <v-btn color="primary" dense hide-details outlined>Descargar en PDF</v-btn>
              </div>
            </div>
            <!-- vista movil para el div -->
            <div class="d-md-none">
              <div class="d-flex justify-center align-center">
                <v-btn color="primary" width="100%" dense hide-details outlined>Enviar por correo
                  electrónico</v-btn>
              </div>
              <div class="d-flex justify-center align-center">
                <p class="mb-0 px-4">o</p>
              </div>
              <div class="d-flex justify-center align-center">
                <v-btn color="primary" width="100%" dense hide-details outlined>Descargar PDF</v-btn>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- Regresar -->
    <v-container grid-list-xs>
      <v-row>
        <v-col cols="12" class="px-0">
          <a href="#" style="text-decoration: none;">
            <p class="mb-0" style="color:gray">
              <v-icon>{{ icons.mdiChevronLeft }}</v-icon>
              Regresar a resultados de búsqueda
            </p>
          </a>
        </v-col>
      </v-row>
    </v-container>
    <!-- 1er card -->
    <!-- varia en dependencia de si es ida, vuelta o multidestino -->
    <v-card>
      <v-container grid-list-xs class="px-4">
        <v-row>
          <v-col cols="12" md="12" class="pb-0">
            <div class="d-block justify-start align-center">
              <div class="d-flex justify-start align-center">
                <h2>Nueva York</h2>
                <v-icon class="px-2" color="primary">
                  {{ icons.mdiSwapHorizontal }}
                </v-icon>
                <h2>Madrid</h2>
              </div>
              <div class="d-flex justify-start align-center">
                <h5>10 de junio, viernes</h5>
                <h5 class="px-2">
                  -
                </h5>
                <h5>17 de junio, viernes</h5>
              </div>
              <div class="d-flex justify-start align-center">
                <h5>Adultos #, Niños #, Infantes #</h5>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3">
            <v-text-field dense outlined hide-details label="Nombre del cliente"></v-text-field>
          </v-col>
          <v-col cols="12" md="2">
            <v-text-field dense outlined hide-details label="Mark-up" type="number"></v-text-field>
          </v-col>
          <v-col cols="12" md="2" class="d-flex align-center">
            <v-radio-group v-model="value" row hide-details class="mt-0">
              <v-radio dense label="%" value="value1"></v-radio>
              <v-radio dense label="USD" value="value2"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="12" md="2" class="d-flex align-center">
            <v-checkbox label="Agregar descuento" dense hide-details outlined class="descuento my-0 py-0"
              v-model="value" value="value"></v-checkbox>
          </v-col>
          <!-- Vista movil del agregar descuento -->
          <div class="d-md-none">
            <v-col cols="12">
              <v-text-field dense outlined hide-details label="Mark-up" type="number"></v-text-field>
            </v-col>
            <v-col cols="12" class="d-flex align-center">
              <v-radio-group v-model="value" row hide-details class="mt-0">
                <v-radio dense label="%" value="value1"></v-radio>
                <v-radio dense label="USD" value="value2"></v-radio>
              </v-radio-group>
            </v-col>
          </div>
          <!-- Fin vista movil del agregar descuento -->
          <v-col cols="12" md="2">
            <v-btn color="primary">Aplicar</v-btn>
          </v-col>
        </v-row>
        <!-- Vista desktop del agregar descuento -->
        <div class="d-none d-md-block">
          <v-row>
            <v-col cols="12" md="3">
            </v-col>
            <v-col cols="12" md="2">
              <v-text-field dense outlined hide-details label="Mark-up" type="number"></v-text-field>
            </v-col>
            <v-col cols="12" md="2" class="d-flex align-center">
              <v-radio-group v-model="value" row hide-details class="mt-0">
                <v-radio dense label="%" value="value1"></v-radio>
                <v-radio dense label="USD" value="value2"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="2" class="d-flex align-center">
            </v-col>
          </v-row>
        </div>
        <!-- Fin de vista desktop del agregar descuento -->
        <v-row>
          <v-col cols="12" class="pb-0">
            <p class="mb-0">Cálculo de ejemplo</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <div class="d-flex justify-start align-center">
              <div class="d-block justify-center mr-4">
                <p class="d-flex justify-center align-center mb-0">Precio inicial</p>
                <v-divider style="border: 1px solid #bebbc3;" class="my-1"></v-divider>
                <p class="d-flex justify-center align-center mb-0">100.00</p>
              </div>
              <div class="d-block justify-center mr-4">
                <p class="d-flex justify-center align-center mb-0">Precio con Mark-up</p>
                <v-divider style="border: 1px solid #bebbc3;" class="my-1"></v-divider>
                <p class="d-flex justify-center align-center mb-0">100.00</p>
              </div>
              <div class="d-block justify-center">
                <p class="d-flex justify-center align-center mb-0">Precio con Descuento</p>
                <v-divider style="border: 1px solid #bebbc3;" class="my-1"></v-divider>
                <p class="d-flex justify-center align-center mb-0">100.00</p>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <!-- 2do card -->
    <v-card class="mt-2">
      <v-card-title primary-title>
        Vuelos
      </v-card-title>
      <v-container grid-list-xs>
        <v-row>
          <v-col cols="12">
            <v-card>
              <v-container grid-list-xs>
                <v-row>
                  <v-col cols="12" class="pt-0 pl-0">
                    <template>
                      <v-simple-table dense fixed-header height="400px" id="bookingTableMine">
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left">
                              </th>
                              <th class="text-left">
                                Horario
                              </th>
                              <th class="text-left">
                                Duración del Vuelo
                              </th>
                              <th class="text-left">
                                Escalas
                              </th>
                              <th class="text-left">
                                Operado por
                              </th>
                              <th class="text-left">
                                No. de Vuelo
                              </th>
                              <th class="text-left">
                                Clase
                              </th>
                              <th class="text-left">
                                Tarifa
                              </th>
                              <th class="text-left">
                                Equipaje de Mano
                              </th>
                              <th class="text-left">
                                Equipaje en bodega
                              </th>
                              <th class="text-left">
                                Precio inicial
                              </th>
                              <th class="text-left">
                                Precio con Mark-up
                              </th>
                              <th class="text-left">
                                Precio con Descuento
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(item, i) in 15" :key="i">
                              <td>
                                <v-checkbox label="" v-model="value" value="value"></v-checkbox>
                              </td>
                              <td>
                                <p class="mb-0">10:00am</p>
                              </td>
                              <td>
                                <p class="mb-0">20mn</p>
                              </td>
                              <td>
                                <p class="mb-0">No</p>
                              </td>
                              <td>
                                <v-tooltip left>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-img v-bind="attrs" v-on="on" lazy-src="isotipo.jpg" max-height="50"
                                      max-width="50" src="../../../../../public/isotipo.jpg"></v-img>
                                  </template>
                                  <span>Iberia</span>
                                </v-tooltip>
                              </td>
                              <td>
                                <p class="mb-0">
                                  CM 321
                                </p>
                              </td>
                              <td>
                                <p class="mb-0">Económica</p>
                              </td>
                              <td>
                                <p class="mb-0">Económica Basic</p>
                              </td>
                              <td>
                                <p class="mb-0">
                                  <v-icon>{{ icons.mdiBagCarryOn }}</v-icon>10kg
                                </p>
                              </td>
                              <td>
                                <p class="mb-0">
                                  <v-icon>{{ icons.mdiBagPersonal }}</v-icon>23kg
                                </p>
                              </td>
                              <td>
                                <p class="mb-0">
                                  170 $</p>
                              </td>
                              <td>
                                <p class="mb-0">
                                  170 $</p>
                              </td>
                              <td>
                                <p class="mb-0">
                                  170 $</p>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </template>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>

import { ref } from '@vue/composition-api'
import {
  mdiChevronLeft,
  mdiSwapHorizontal,
  mdiBagCarryOn,
  mdiBagPersonal,
} from '@mdi/js'

export default {
  setup() {
    const tab = ref('')

    return {
      tab,

      // icons
      icons: {
        mdiChevronLeft,
        mdiSwapHorizontal,
        mdiBagCarryOn,
        mdiBagPersonal,
      },
    }
  },
  methods: {},
}
</script>

<style scoped>
.fixHeightMine {
  height: auto;
  width: 100% !important;
  background-color: #daccf4;
  position: fixed;
  z-index: 10;
  bottom: 0px;
}

@media screen and (max-width: 1024px) and (min-width: 200px) {
  .fixHeightMine {
    height: auto;
    width: 100% !important;
    background-color: #daccf4;
    position: fixed;
    z-index: 10;
    bottom: 0px;
    left: 0;
  }
}

.imgBookingMine {
  /* max-width: 120px !important; */
  height: 120px !important;
}

#bookingTableMine th {
  min-width: 120px;
}

#bookingTableMine th:nth-child(1) {
  min-width: auto;
}
</style>
