<template>
  <div>
    <BookingFlight />
  </div>
</template>

<script>
import {
} from '@mdi/js'

import BookingFlight from '../form-layout/utils/BookingFlight.vue'

export default {
  components: {
    BookingFlight,
  },
  setup() {
    return {

    }
  },
}
</script>
